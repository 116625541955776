<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal"
      style="margin-bottom: 20px"
      >{{ $t("all.add") }}</a-button
    >
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      width="50%"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" @submit="addClass">
        <a-form-item :label="$t('etablissement.nom')">
          <a-input
            :placeholder="$t('etablissement.nom')"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('all.requiredName'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="$t('etablissement.niveau')">
          <a-select
            v-decorator="[
              'level',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.niveau'),
                  },
                ],
              },
            ]"
            :placeholder="$t('choisir.niveauClass')"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
              >{{ value }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('etablissement.fraisInscri')">
          <a-input
            type="number"
            step="0.5"
            v-decorator="[
              'inscription',
              {
                rules: [
                  {
                    required: false,
                    min: 0,
                    message: $t('requis.fraisInscri'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-divider>Frais mensuelle</a-divider>

        <a-form-item :label="$t('etablissement.fraisMensuel')">
          <a-input
            type="number"
            step="0.5"
            button-style="solid"
            v-decorator="[
              'monthly',
              {
                rules: [
                  {
                    required: true,
                    min: 0,
                    message: $t('requis.fraisMensuel'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-divider>Frais des tranches</a-divider>
        <a-form-item>
          <a-row v-for="(item, index) in tranchesList" :key="index">
            <a-col :span="3"> Tranche {{ item.trancheNumber }} : </a-col>
            <a-col :span="11">
              <a-select
                v-model="item.months"
                mode="multiple"
                style="width: 100%"
                placeholder="Mois"
              >
                <a-select-option
                  v-for="i in monthRangesList"
                  :key="i"
                  :value="i"
                >
                  {{ moment(i).format("MMMM") }}
                </a-select-option>
              </a-select></a-col
            >
            <a-col :span="8">
              <a-input-number
                style="width: 100%"
                class="ml-2"
                :value="item.amount"
                v-model="item.amount"
                placeholder="Montant"
            /></a-col>
            <a-col :span="2">
              <a-button
                type="dashed"
                class="ml-4"
                shape="circle"
                icon="delete"
                @click="
                  () => {
                    tranchesList.splice(index, 1);
                  }
                "
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8"></a-col>
            <a-col :span="14">
              <a-button
                type="primary"
                class="mr-3 m-auto"
                @click="
                  () => {
                    tranchesList.push({
                      trancheNumber: tranchesList.length + 1,
                      amount: undefined,
                      months: [],
                    });
                  }
                "
              >
                Ajouter une tranche
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
            >{{ $t("action.ajouter") }}</a-button
          >
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingClasse"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template
        v-for="col in ['name', 'schoolarYear', 'createdAt']"
        :slot="col"
        slot-scope="text, record"
      >
        <div v-if="!['createdAt', 'schoolarYear'].includes(col)" :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :type="col == 'name' ? 'text' : 'number'"
            :value="text"
            @change="(e) => handleChange(e.target.value, record._id, col)"
          />
          <template v-else>{{ text }}</template>
        </div>
        <div v-else :key="col" style="text-align: center">
          <template>{{ text }}</template>
        </div>
      </template>
      <template slot="level" slot-scope="text, record">
        <div v-if="record.editable">
          <a-select
            style="width: 150px"
            :default-value="text ? text.toString() : ''"
            :placeholder="$t('choisi.niveauClass')"
            @change="(e, o) => handleChange(e, record._id, 'level')"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
              >{{ value }}</a-select-option
            >
            <!--  <a-select-option value="1">{{
              $t("niveau.niveau1")
            }}</a-select-option>
            <a-select-option value="2">{{
              $t("niveau.niveau2")
            }}</a-select-option>
            <a-select-option value="3">{{
              $t("niveau.niveau3")
            }}</a-select-option>
            <a-select-option value="4">{{
              $t("niveau.niveau4")
            }}</a-select-option>
            <a-select-option value="5">{{
              $t("niveau.niveau5")
            }}</a-select-option>
            <a-select-option value="6">{{
              $t("niveau.niveau6")
            }}</a-select-option>-->
          </a-select>
        </div>

        <div style="text-align: center" v-else>
          {{ levelLabes[Number(text) + 2] }}
        </div>
      </template>
      <template slot="tranches" slot-scope="text">
        <li v-for="t in text" :key="t.trancheNumber">
          {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
        </li>
      </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a-button
              size="small"
              type="primary"
              @click="() => save(record._id)"
              >{{ $t("action.enregistrer") }}</a-button
            >

            <a-button size="small" type="danger">
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a>{{ $t("action.annuler") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
          <span v-else>
            <a-button
              type="primary"
              :disabled="editingKey !== ''"
              @click="() => edit(record._id)"
            >
              <a-icon type="edit" />
              {{ $t("action.modifier") }}
            </a-button>
          </span>
        </div>
      </span>
      <span slot="operationSupprimer" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-button :disabled="editingKey !== ''" type="danger">
              <a-popconfirm
                :title="$t('requis.supp')"
                @confirm="() => supp(record._id)"
              >
                <a>{{ $t("action.supprimer") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
        </div>
      </span>
    </a-table>
    <!-- UPDATE DRAWER -->
    <a-drawer
      v-if="selectedClassRoom"
      :title="
        $t('etablissement.modifierEmploi', { name: selectedClassRoom.name })
      "
      :width="720"
      :visible="updateDrawer"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose('update')"
    >
      <a-form :form="form" layout="vertical">
        <a-row :gutter="16">
          <span class="p-1">
            {{ $t("actualite.trimestre1") }}
            <a-tag
              v-if="selectedClassRoom.emplois && selectedClassRoom.emplois.sem1"
              color="green"
            >
              <a
                :href="settings.base_url + '/' + selectedClassRoom.emplois.sem1"
                target="_blank"
                rel="noopener noreferrer"
                >{{ $t("action.voir") }}</a
              >
            </a-tag>
          </span>
          <a-col :span="24">
            <a-upload-dragger
              class="p-1"
              v-decorator="[
                'sem1',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="false"
              :before-upload="beforeUpload"
              :file-list="fileList1"
              :customRequest="addToSem1"
              :remove="handleClose"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <span class="p-1">
            {{ $t("actualite.trimestre2") }}
            <a-tag
              v-if="selectedClassRoom.emplois && selectedClassRoom.emplois.sem2"
              color="green"
            >
              <a
                :href="settings.base_url + '/' + selectedClassRoom.emplois.sem2"
                target="_blank"
                :v-if="selectedClassRoom.emplois.sem2"
                rel="noopener noreferrer"
                >{{ $t("action.voir") }}</a
              >
            </a-tag>
          </span>
          <a-col :span="24">
            <a-upload-dragger
              class="p-1"
              v-decorator="[
                'sem2',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="false"
              :before-upload="beforeUpload"
              :file-list="fileList2"
              :customRequest="addToSem2"
              :remove="handleClose"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <span class="p-2">
            {{ $t("actualite.trimestre3") }}
            <a-tag
              v-if="selectedClassRoom.emplois && selectedClassRoom.emplois.sem3"
              color="green"
            >
              <a
                :href="settings.base_url + '/' + selectedClassRoom.emplois.sem3"
                target="_blank"
                rel="noopener noreferrer"
                >{{ $t("action.voir") }}</a
              >
            </a-tag>
          </span>
          <a-col :span="24">
            <a-upload-dragger
              class="p-1"
              v-decorator="[
                'sem3',
                {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                },
              ]"
              :multiple="false"
              :before-upload="beforeUpload"
              :file-list="fileList3"
              :customRequest="addToSem3"
              :remove="handleClose"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="file-image" />
              </p>
              <p class="ant-upload-text">
                {{ $t("etablissement.clickOrDragToUpload") }}
              </p>
            </a-upload-dragger>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose('update')">{{
          $t("action.annuler")
        }}</a-button>
        <a-button type="primary" @click="handleUpdate">{{
          $t("action.modifier")
        }}</a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import { getRangeMonthsSY } from "@/utilities/settings";

var classesData = [];
export default {
  name: "classes",
  computed: mapState(["settings", "user"]),
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "classForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Classes",
    });

    this.getClassRooms();
    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;
    this.monthRangesList = getRangeMonthsSY(
      this.settings.activeSchoolarYear,
      this.settings.schoolarYears
    );
    console.log(this.monthRangesList);
  },
  data() {
    return {
      moment,
      schoolType: null,
      photo: null,
      fileList2: [],
      fileList1: [],
      fileList3: [],
      tranchesList: [],
      monthRangesList: [],
      selectedClassRoom: {
        emplois: {
          sem1: "",
          sem2: "",
          sem3: "",
        },
      },
      updateDrawer: false,
      columns: [
        {
          title: this.$t("etablissement.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.niveau"),
          dataIndex: "level",
          key: "level",
          width: "150px",
          scopedSlots: { customRender: "level" },
          sorter: (a, b) => a.level - b.level,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisInscri"),
          dataIndex: "inscription",
          key: "inscription",
          scopedSlots: { customRender: "inscription" },
          sorter: (a, b) => a.inscription - b.inscription,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisMensuel"),
          dataIndex: "monthly",
          key: "monthly",
          scopedSlots: { customRender: "monthly" },
          sorter: (a, b) => a.monthly - b.monthly,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Tranches",
          dataIndex: "tranches",
          key: "tranches",
          scopedSlots: { customRender: "tranches" },
        },
        {
          title: this.$t("etablissement.anneeScolaire"),
          dataIndex: "schoolarYear",
          key: "schoolarYear",
          scopedSlots: { customRender: "schoolarYear" },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.dateCreation"),
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "createdAt" },
        },
        {
          title: this.$t("all.edit"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      levelLabes: [
        this.$t("niveau.niveauPre"),
        this.$t("niveau.niveau0"),
        "",
        this.$t("niveau.niveau1"),
        this.$t("niveau.niveau2"),
        this.$t("niveau.niveau3"),
        this.$t("niveau.niveau4"),
        this.$t("niveau.niveau5"),
        this.$t("niveau.niveau6"),
        this.$t("niveau.niveau7"),
        this.$t("niveau.niveau8"),
        this.$t("niveau.niveau9"),
        this.$t("niveau.niveau10"),
        this.$t("niveau.niveau11"),
        this.$t("niveau.niveau12"),
        this.$t("niveau.niveau13"),
      ],
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
    };
  },
  methods: {
    async getClassRooms() {
      await apiClient
        .post("/classrooms/filter", {
          query: {
            schoolarYear: this.settings.activeSchoolarYear,
            status: "active",
          },
          aggregation: {
            $project: {
              __v: 0,
              updatedAt: 0,
              teachers: 0,
            },
          },
        })
        .then((res) => {
          classesData = [...res.data];
          classesData = classesData.map((elem) => {
            var sc = "";
            try {
              sc = this.settings.schoolarYears.filter(
                (s) => s._id == elem.schoolarYear
              )[0].name;
            } catch {
              sc = "-";
            }
            return {
              ...elem,
              key: elem._id,
              createdAt: moment(elem.createdAt).format("DD-MM-YYYY"),
              schoolarYear: sc,
            };
          });
          this.data = classesData;
          this.cacheData = classesData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => this.$message.error(this.$t("error.aucClasse")));
    },
    handleClose() {
      this.fileList1 = [];
      this.fileList2 = [];
      this.fileList3 = [];
    },
    onClose(type) {
      this.updateDrawer = false;
    },
    handleUpdate(e) {
      e.preventDefault();

      let formData = new FormData();
      if (this.fileList1[0]) formData.append("sem1", this.fileList1[0]);
      if (this.fileList2[0]) formData.append("sem2", this.fileList2[0]);
      if (this.fileList3[0]) formData.append("sem3", this.fileList3[0]);

      apiClient({
        method: "patch",
        url: "/classrooms/emplois/" + this.selectedClassRoom._id,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data;`,
        },
      })
        .then((res) => {
          this.onClose("update");
          this.getClassRooms();
          this.$message.success(this.$t("success.emploiMSA"));
          this.fileList1 = [];
          this.fileList2 = [];
          this.fileList3 = [];
        })
        .catch((e) => {
          this.$message.error(this.$t("error.modif"));
        });
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(this.$t("error.fichiers"));
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t("error.image"));
      }
      if (isJpgOrPng && isLt2M) {
        this.$message.success(
          this.$t("success.fileUploadedPleaseSave", { name: file.name })
        );
        this.photo = file;
      }
      return isJpgOrPng && isLt2M;
    },
    addToSem1() {
      this.fileList1 = [this.photo];
    },
    addToSem2() {
      this.fileList2 = [this.photo];
    },
    addToSem3() {
      this.fileList3 = [this.photo];
    },
    updateEmplois(record) {
      this.selectedClassRoom = record;
      this.updateDrawer = true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addClass(e) {
      e.preventDefault();
      console.log(this.tranchesList);
      const thereEmpty = this.tranchesList.filter(
        (s) => !s.trancheNumber || !s.amount
      );

      if (thereEmpty.length > 0) {
        this.$message.warning("Vérifier les frais des tranches");
        return;
      }
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;
          values.level = Number(values.level);
          values.tranches = this.tranchesList;
          apiClient
            .put("/classrooms", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.classeAjout"));
              res.data.key = res.data._id;
              this.data.push(this.prepareClaasRoom(res.data));
              this.cacheData.push(this.prepareClaasRoom(res.data));
              this.handleCancel();
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(this.$t("error.impoAjoutClasse"));
            })
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    prepareClaasRoom(classroom) {
      var sc = "";
      try {
        sc = this.settings.schoolarYears.filter(
          (s) => s._id == classroom.schoolarYear
        )[0].name;
      } catch {
        sc = "-";
      }
      return {
        ...classroom,
        key: classroom._id,
        createdAt: moment(classroom.createdAt).format("DD-MM-YYYY"),
        schoolarYear: sc,
      };
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        console.log(value, typeof value);
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = {
        name: target.name,
        inscription: target.inscription,
        monthly: target.monthly,
        level: target.level,
      };
      apiClient
        .patch("/classrooms/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.classeMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.modif"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .patch("/classrooms/" + key, { data: { status: "inactive" } })
        .then(() => {
          this.$message.success(this.$t("success.classeSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.classeNonSupp")));
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
